import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {getUrlVars} from '../../../util'


import {
	Image,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody } from '../../../components/layouts'

import {
	Loader,
	Button
} from '../../../components/ui'

import {
	getOrganization, setOrganization
} from '../../../factory'

import Layout from '../../../components/layout'
import theme from '../../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const oid = getUrlVars().oid
		this.props.getOrganization(oid)
	}

	// in your component
	addDefaultSrc(ev) {
		ev.target.src = 'https://flourishfiles.blob.core.windows.net/files/Marketing/Patterns/LogoPatternWebSmall.jpg'
	}

	render() {
		const {activeOrganization, token, pending} = this.props

		return (
			<Layout>
				<Container>
					<PhoneBody
						title={activeOrganization.title}
						style={{flex: 1, justifyContent: 'center', textAlign: 'center'}}
					>
						<Image
							src={activeOrganization.image_url}
							onError={this.addDefaultSrc}
							width={'80%'}
							height={'auto'}
							margin={'auto'}
							maxHeight={"300px"}
						/>
						<Heading as="h1" fontSize={24} marginBottom={0}>{activeOrganization.title}</Heading>
						{activeOrganization.address && <Paragraph marginBottom={0} fontSize={16} color={theme.colors.dustGray} margin="5px 20px">{activeOrganization.address.city}, {activeOrganization.address.state.name}</Paragraph>}
						<Paragraph fontSize={18} margin="5px 20px">{activeOrganization.mission}</Paragraph>
						<Button onClick={()=>this.props.setOrganization(activeOrganization.organization_id, [activeOrganization.default_project_id], token)} style={{margin: 'auto', marginTop: 15}}>Support this Cause</Button>
						{/* <Flex row style={{width: '100%',  paddingLeft: '10%', marginBottom: 10}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/MobileApps/CauseIcons/linkIcon.svg"}
								height="1em"
								width="auto"
								marginRight="15px"
							/>
							<Paragraph>{activeOrganization.website}</Paragraph>
						</Flex>
						<Flex row style={{width: '100%',  paddingLeft: '10%', marginBottom: 10}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/MobileApps/CauseIcons/emailIcon.svg"}
								height="1em"
								width="auto"
								marginRight="15px"
							/>
							<Paragraph>{activeOrganization.email}</Paragraph>
						</Flex>
						<Flex row style={{width: '100%',  paddingLeft: '10%', marginBottom: 10}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/MobileApps/CauseIcons/phoneIcon.png"}
								height="1em"
								width="auto"
								marginRight="15px"
							/>
							<Paragraph>{activeOrganization.phone}</Paragraph>
						</Flex> */}
						{pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Loading...</Heading>
								</div>
							</div>
						</div>
						}
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	activeOrganization: PropTypes.object,
	getOrganization: PropTypes.func,
	token: PropTypes.string,
	setOrganization: PropTypes.func,
	pending: PropTypes.bool
}

IndexPage.defaultProps = {
	activeOrganization: {address: {city: '', state: {name: ''}} }
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		activeOrganization: state.discoverState.activeOrg,
		pending: state.discoverState.pending,
		token: state.authState.accessToken,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getOrganization: (oid) => dispatch(getOrganization(oid)),
		setOrganization: (oid, pids, token) => dispatch(setOrganization(oid, pids, token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
